const cookieNames = {
  auth: {
    token: '__b_a_T',
  },
  ui: {
    lang: '__b_ui_L',
  },
}

export default cookieNames
